import React from 'react'
import ReactDOM from 'react-dom'
import { Option } from 'funfix-core'
import * as R from 'ramda'
import App, { PrivacyPolicyConfirmationState } from './App'
import { isMobile } from './isMobile'

const currentScript = (document.currentScript ||
  document.querySelector(
    'script[src$="/skills/zozo-cs/custom-popup/app.js"]'
  )) as HTMLElement | null
const init = () => {
  const popupIconUrl = currentScript ? currentScript.dataset.popupIconUrl : undefined
  const privacyPolicyUrl = Option.of(currentScript)
    .flatMap(x => Option.of(x.dataset.privacyPolicyUrl))
    .getOrElseL(() => '/info/pp.html')
  const isPpm = Option.of(currentScript)
    .flatMap(x => Option.of(x.dataset.ppm))
    .map(x => x.toLowerCase() === 'true')
    .getOrElseL(() => false)
  const privacyPolicyConfirmationState = new PrivacyPolicyConfirmationState(privacyPolicyUrl)
  const div = document.createElement('div')
  document.body.appendChild(div)
  ReactDOM.render(
    <App
      popupIconUrl={popupIconUrl}
      privacyPolicyConfirmationState={privacyPolicyConfirmationState}
      isPpm={isPpm}
      isMobile={isMobile()}
    />,
    div
  )
}

if (document.querySelector('div[class^="krkr-button"]')) {
  init()
} else {
  const observer = new MutationObserver(mutations => {
    const krkrButton = R.flatten(mutations.map(x => Array.from(x.addedNodes)))
      .map(x => x as HTMLElement)
      .find(x => /krkr-button/.test(x.className))
    if (krkrButton) {
      observer.disconnect()
      init()
    }
  })
  observer.observe(document.body, { childList: true, subtree: true })
}
