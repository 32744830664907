import React from 'react'
import {
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Checkbox,
  FormControlLabel,
  Container,
  IconButton,
  Slide,
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import CheckIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
  },
  caption: {
    fontSize: '12px',
    lineHeight: '20.5px',
    fontWeight: 300,
    color: (props: { fontColor: string }) => props.fontColor,
  },
  chatStartButton: {
    height: 51,
    marginTop: 6,
    color: 'white',
    boxShadow: 'none',
    // '&:hover': {
    //   boxShadow: 'none',
    // },
  },
  chatStartButtonDisabled: {
    height: 51,
    marginTop: 6,
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white !important',
    opacity: 0.5,
  },
}))

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface PrivacyPolicyConfirmationProps {
  checked: boolean
  onConfirmCheckChanged: (checked: boolean) => void
  onChatStartClick: React.MouseEventHandler<HTMLElement>
  open: boolean
  onClose: React.MouseEventHandler<HTMLElement>
  avatarImageUrl: string
  privacyPolicyUrl: string
  isPpm: boolean
  fontColor: string
}

export const PrivacyPolicyConfirmationMobile: React.FC<PrivacyPolicyConfirmationProps> = props => {
  const {
    checked,
    onConfirmCheckChanged,
    onChatStartClick,
    open,
    onClose,
    avatarImageUrl,
    privacyPolicyUrl,
    isPpm,
  } = props
  const classes = useStyles(props)
  const [htmlOverflowValue, setHtmlOverflowValue] = React.useState(
    document.documentElement.style.overflow
  )
  const [bodyOverflowValue, setBodyOverflowValue] = React.useState(document.body.style.overflow)
  const [htmlHeightValue, setHtmlHeightValue] = React.useState(
    document.documentElement.style.height
  )
  const [bodyHeightValue, setBodyHeightwValue] = React.useState(document.body.style.height)
  const [htmlScrollValue, setHtmlScrollValue] = React.useState(
    (document.scrollingElement || document.documentElement).scrollTop
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ className: classes.root }}
      fullScreen
      TransitionComponent={Transition}
      transitionDuration={350}
      hideBackdrop
      disableScrollLock
      onEntered={() => {
        setHtmlOverflowValue(document.documentElement.style.overflow)
        document.documentElement.style.overflow = 'hidden'
        setHtmlHeightValue(document.documentElement.style.height)
        document.documentElement.style.height = '100%'
        setBodyOverflowValue(document.body.style.overflow)
        document.body.style.overflow = 'hidden'
        setBodyHeightwValue(document.body.style.height)
        document.body.style.height = '100%'
        setHtmlScrollValue((document.scrollingElement || document.documentElement).scrollTop)
      }}
      onExit={() => {
        document.documentElement.style.overflow = htmlOverflowValue
        document.documentElement.style.height = htmlHeightValue
        document.body.style.overflow = bodyOverflowValue
        document.body.style.height = bodyHeightValue
        if (document.scrollingElement) {
          document.scrollingElement.scrollTop = htmlScrollValue
        } else {
          document.documentElement.scrollTop = htmlScrollValue
        }
      }}
    >
      <DialogTitle
        disableTypography
        style={{
          height: 50,
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: '#FBFBFB',
          borderBottom: '1px solid #E9E9E9',
        }}
      >
        <Typography
          variant="subtitle2"
          display="inline"
          style={{ fontSize: 18, lineHeight: '50px' }}
        >
          {`教えて！箱猫${isPpm ? 'タカシちゃん' : 'マックスくん'}`}
        </Typography>
        <IconButton
          style={{
            cursor: 'pointer',
            position: 'absolute',
            right: 12,
            height: 50,
            width: 26,
            boxShadow: 'none',
          }}
          size="small"
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Container style={{ paddingTop: 33, paddingBottom: 24 }}>
        <img
          src={avatarImageUrl}
          style={{
            maxHeight: 125,
            maxWidth: 125,
            marginBottom: 8,
          }}
          alt={`箱猫${isPpm ? 'タカシちゃん' : 'マックスくん'}`}
        />
        <Typography
          variant="subtitle2"
          style={{ fontSize: 11, lineHeight: '11px', marginBottom: 9 }}
        >
          チャットボットサービス
        </Typography>
        <Typography
          variant="h5"
          style={{
            marginBottom: 20,
            fontSize: 18,
            lineHeight: '18px',
          }}
        >
          {`教えて！箱猫${isPpm ? 'タカシちゃん' : 'マックスくん'}`}
        </Typography>
        <Typography className={classes.caption}>チャットボットをご利用するには、</Typography>
        <Typography className={classes.caption}>プライバシーポリシーの同意が必要です。</Typography>
        <Typography className={classes.caption}>以下の規約をご確認の上、お進み下さい。</Typography>
      </Container>
      <DialogContent
        style={{
          padding: 0,
          flexGrow: 0,
          overflow: 'auto',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: '#e0e0e0',
          marginLeft: 20,
          marginRight: 20,
          minHeight: 100,
        }}
      >
        <iframe
          title="プライバシーポリシー"
          src={privacyPolicyUrl}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
          frameBorder={0}
        />
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'center',
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 4,
          marginBottom: 12,
          flexDirection: 'column',
        }}
      >
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={x => onConfirmCheckChanged(x.target.checked)}
                color="primary"
                icon={<CheckIcon color="disabled" />}
                checkedIcon={<CheckIcon />}
              />
            }
            label={<Typography variant="body2">プライバシーポリシーに同意する</Typography>}
          />
        </div>
        <Button
          style={{ width: '95%' }}
          className={checked ? classes.chatStartButton : classes.chatStartButtonDisabled}
          variant="contained"
          onClick={onChatStartClick}
          color="primary"
          disabled={!checked}
        >
          <Typography>チャットをはじめる</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
